<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CCard>
            <CCardHeader>
                <slot name="header">
                    <CIcon name="cil-globe-alt"/><strong> Worlds </strong>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                        :items="items"
                        :fields="fields"
                        :hover="hover"
                        pagination >
                    <template #status="{item}">
                        <td>
                            <CBadge :color="$config.flagClr(item.flags)">{{ $config.flagTxt(item.flags) }}</CBadge>
                        </td>
                    </template>
                    <template #manage="{item}">
                        <td class="py-2">
                            <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="manageWorld(item.world_id)">
                                Manage
                            </CButton>
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'Worlds',
        components: {
            Loading
        },
        data(){
            return {
                items: [],
                fields: ['world_id', 'world_name', 'ip', 'port', 'world_key', 'status', 'manage'],
                hover: true,
                isLoading: false
            }
        },
        methods: {
            getWorlds() {
                this.isLoading = true;
                this.$http.get(this.$config.gameapi + '/World/' + localStorage.env + '/', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.items = jsonData;
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                }).finally(function () { this.isLoading = false; });;
            },
            manageWorld(id) {
                this.$router.push({ name: 'World', params: { id: id } })
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        created() {
            this.getWorlds();
        }
    }
</script>

<style scoped>

</style>
